'use client';

import dynamic from 'next/dynamic';
import React from 'react';

const TranslationProviderClient = dynamic(
  () => import('@/components/core/TranslationProviderClient'),
  { ssr: false }
);

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  return <TranslationProviderClient>{children}</TranslationProviderClient>;
};

export default TranslationProvider;
