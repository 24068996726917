'use client';

import React, { Suspense } from 'react';

// Suspense> is needed for the build command to succeed. Specifically, it's needed to generate the
// static pages. Without it, the useSearchParams hook doesn't work.
export const SuspenseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Suspense>{children}</Suspense>;
};
