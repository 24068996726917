import(/* webpackMode: "eager" */ "/build/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/frontend/src/components/core/FontProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseProvider"] */ "/build/frontend/src/components/core/SuspenseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/frontend/src/components/core/TranslationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/frontend/src/components/SentryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppContextProvider"] */ "/build/frontend/src/configuration/AppContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PicoProvider"] */ "/build/frontend/src/pico/PicoProvider.tsx");
