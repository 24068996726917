'use client';

import classNames from 'classnames';
import { Inter } from 'next/font/google';
import React from 'react';

const inter = Inter({ subsets: ['latin'] });

const FontProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <body className={classNames(inter.className, 'h-full flex flex-col')}>
      {children}
    </body>
  );
};

export default FontProvider;
