'use client';

import * as Sentry from '@sentry/react';
import React from 'react';

const SENTRY_RELEASE = `user-service-${process.env.NEXT_PUBLIC_GIT_SHA ?? 'undefined'}`;

function initSentry() {
  if (
    !process.env.NEXT_PUBLIC_SENTRY_DSN ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
  ) {
    return;
  }

  Sentry.init({
    enabled: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: SENTRY_RELEASE,
    attachStacktrace: true,
    maxBreadcrumbs: 100,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    integrations: [
      Sentry.breadcrumbsIntegration(),
      Sentry.browserApiErrorsIntegration(),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 1.0,
  });
}

export default function SentryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  React.useEffect(() => {
    initSentry();
  }, []);

  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
}
